class MapapApi {
    constructor(){
      // this.apiSites = 'http://localhost:5000/api/v0/sites'
      // this.apiSites = 'http://localhost:5000/sites';
      this.apiSites = 'https://dev-mapap.firebaseapp.com/api/v0/sites'
    }

    
    boundsCheck = (toCheck, min, max) => {
      if(toCheck < min) return min;
      if(toCheck > max) return max;
      return toCheck;
    }
    createBoundsParam = (bounds) =>{
      const swLat = this.boundsCheck(bounds._southWest.lat, -90, 90);
      const swLng = this.boundsCheck(bounds._southWest.lng, -180, 180);
      const neLat = this.boundsCheck(bounds._northEast.lat, -90, 90);
      const neLng = this.boundsCheck(bounds._northEast.lng, -180, 180);
  
      const params = {swlat: swLat, swlng: swLng,
                      nelat: neLat, nelng: neLng};
      return params;
    }

    sendSiteRequest = (token, params) =>{
      let authToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjgyZjBiNDZjYjc1OTBjNzRmNTNhYzdhOWUwY2IxYzAzMjRlY2RkNzUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRGF2aWQgTGkiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDYuZ29vZ2xldXNlcmNvbnRlbnQuY29tLy1zTVFORXd5VDlCQS9BQUFBQUFBQUFBSS9BQUFBQUFBQUFBQS9BQ0hpM3JjM2pDUDNGNnB0cllOWEI3QkxQN2wtbkw0Y1BBL21vL3Bob3RvLmpwZyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9kZXYtbWFwYXAiLCJhdWQiOiJkZXYtbWFwYXAiLCJhdXRoX3RpbWUiOjE1NTg1NzgxNzcsInVzZXJfaWQiOiI5SEFNemNHVUhCUHdJZThjZktZOVE2VjgzYksyIiwic3ViIjoiOUhBTXpjR1VIQlB3SWU4Y2ZLWTlRNlY4M2JLMiIsImlhdCI6MTU1ODU3ODE3NywiZXhwIjoxNTU4NTgxNzc3LCJlbWFpbCI6ImRsaXBpbm83OUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwMDIwOTc0MzE5ODU3Njk4NjYyMCJdLCJlbWFpbCI6WyJkbGlwaW5vNzlAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.a7iIT2U-bIesAqCq45ZXQLSLOvBDEd-mA5xbioRaSPjddnrXDc9NQ4Wm8i9K6dPftjTmVe-zvSBEu42u1BCKduebf_exeljNaYMdjjn0_mp2rkPv0glKi0AKLkSVQSBCJ7fDQzC3_fJc1NiRrEeR77KPiyslEgHg3veeSbS_Km6hz1Xu1pSdwZ_1sVSnsdRoPxl8lD6LNXIIipduiNJshh0GfszjviCLSS_coTpkNxiaYhgsqPkrpkSB67o3Ggmq3OHcFMbNw_iggYsiPOW7lors8MWWRVHn8_dnDjce4CcTg8tyX836BJArznTiX6uqWraXuRUHZW-fUGElPjE_dQ';
      const fetchExtra = {method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
      };


      var url = new URL(this.apiSites);
      url.search = new URLSearchParams(params);
      return fetch(url.toString(), fetchExtra).then(res => res.json())
    }

}

export default MapapApi;