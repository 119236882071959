import React, { Component } from 'react';
import { 
  BrowserRouter as Router,
  Route, 
} from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import MapapMapPage from '../MapVisual';

import * as ROUTES from '../../constants/routes';

import { withAuthentication } from '../Session';

class App extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount(){
    loadReCaptcha();
  }
  
  render(){
    return (
      <Router>
        <div>
          <Navigation />
          <hr />

          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.MAP} component={MapapMapPage} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          </div>
      
      </Router>
    );
  }
}

export default withAuthentication(App);