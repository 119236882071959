import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    // console.log(config);
    app.initializeApp(config);
  /* Helper */

  this.serverValue = app.database.ServerValue;
  this.emailAuthProvider = app.auth.EmailAuthProvider;
  /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.rdb = app.database();
   /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
  
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
  });
  
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API 
  // Not really sure how this works yet 5/22/2019
  //*** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid).get()
          .then(snapshot => {
            if(snapshot.exists){
              const dbUser = snapshot.data();

              // default empty roles
              if (!dbUser.roles) {
                dbUser.roles = {};
              }
              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };
  
              next(authUser);
            } else {
              fallback();
            }
        
          });
      } else {
        fallback();
      }
    });
  // *** User API from firestore***

  user = uid => this.db.collection("users").doc(`${uid}`);
  users = () => this.db.collection("users");
}

export default Firebase;