import React from 'react';

// const { Provider, Consumer } = React.createContext("query");
const MapQueryContext = React.createContext(null);
class MapInputSubmit extends React.Component {

}

const MapInputEvent = () => (
  <MapQueryContext.Consumer>
     {({ queries, update }) => (
    <div>
      <label>The input 
        <select value={queries.status} onChange={e=>update({status:e.target.value})}>
          <option value='1'>All</option>
          <option value='2'>With numbers</option>
          <option value='3'>Raw Sites</option>
          <option value='4'>Other</option>
        </select>
      </label>
      <label>Site Name:
        <input type="text" value={queries.name} onChange={e=>update({name:e.target.value})}/>
      </label>
      <label>Site Address:
        <input type="text" value={queries.address} onChange={e=>update({name:e.target.value})}/>
      </label>
    </div>
     )}
  </MapQueryContext.Consumer>
  
);

class MapDisplayer extends React.Component {

  render() {
    return (
      <MapQueryContext.Consumer>
        {({ queries: { status, name } }) => (
          <p>Display Something {name} and status {status} </p>
        )}
      </MapQueryContext.Consumer>
    );
  }
}


class MapapVisualizer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      status: 3,
      name: '',
      address: '',
    };
    this.update = query => this.setState(query);
  }

  render(){
    const { update } = this;
    const queries = this.state;
    return(
      <MapQueryContext.Provider value={{ queries, update }}>
        <main>
          <section>
              <MapInputEvent></MapInputEvent>
          </section>
          <section>
            <MapDisplayer></MapDisplayer>
          </section>
        </main>
      </MapQueryContext.Provider>
    );
  }
}

export default MapapVisualizer;