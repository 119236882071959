import L from 'leaflet';
const placeIconBase = L.Icon.extend({
  options:{

    iconSize: [40, 40]
  }
});
const placeUnknownStatus = new placeIconBase(
  {iconUrl: require('../assets/img/icons8-home-address-grey-64.png')});

const placeWithMap = new placeIconBase(
  {iconUrl: require('../assets/img/icons8-home-address-yellow-64.png')});
  
const placeComplete = new placeIconBase(
  {iconUrl: require('../assets/img/icons8-home-address-red-64.png')});

const placeNoMap = new placeIconBase(
  {iconUrl: require('../assets/img/icons8-home-address-black-96.png')});
    
  // const iconPlace = new L.Icon({
//     iconUrl: require('../assets/img/icons8-home-address-grey-64.png'),
//     iconAnchor: null,
//     popupAnchor: null,
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//     iconSize: new L.Point(40, 40),
//     className: 'leaflet-div-icon'
// });

export {placeUnknownStatus, placeNoMap, placeWithMap, placeComplete};