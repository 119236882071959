import React from 'react';
import { compose } from 'recompose';
import L from 'leaflet';
import './map.css';

import MapapApi from '../../tools/mapapapi';
// import '../../../node_modules/leaflet/dist/leaflet.css';//Cant find some icons when this is used
// import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
// import 'leaflet-extra-markers';
// import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';

import { withAuthorization, withEmailVerification } from '../Session';
import { placeUnknownStatus, placeNoMap, placeWithMap, placeComplete } from '../../tools/mapapicons';

const style = {
  width: "100%",
  height: "600px"
};
class MapapMap extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      mapCenter:[33.592395, -112.237662],
      totalCount: 0
    };    
    this.query = {
      status: 0,
      name: '',
      address: '',
      useBounds: false
    };
    Object.assign(this.state, this.query);
  
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.enterKeySubmit = this.enterKeySubmit.bind(this);

    this.mapapApi = new MapapApi();
    //Layers 
    const maxZoom = 20;
    const osmLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>';
    const thunLink = '<a href="http://thunderforest.com/">Thunderforest</a>';

    const osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const osmAttrib = '&copy; ' + osmLink + ' Contributors';
    const landUrl = 'http://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png';
    const thunAttrib = '&copy; '+osmLink+' Contributors & '+thunLink;

    this.Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {maxZoom:20, 
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'});
    
    this.osmMap = L.tileLayer(osmUrl, {maxZoom:maxZoom, attribution: osmAttrib});
    // this.landMap = L.tileLayer(landUrl, {attribution: thunAttrib});
  }

  passStateToQueryAndUpdateMap(){
    
    Object.keys(this.query).forEach(key=>{
      this.query[key] = this.state[key];
      if(this.query[key] == undefined){
        delete this.query[key];
      }
      // console.log(this.query[key], key, this.state[key]);
    });
    console.log('pass and update');
    this.updateMap();
  }
  enterKeySubmit(event) {
    if (event.key === 'Enter') {
      this.passStateToQueryAndUpdateMap();
    }
  }
  handleChange(event) {
    let settingsHolder = {};

    let key = event.target.name;
    console.log(key);
    let val = event.target.value;
    if(key === 'useBounds'){
      val = event.target.checked;
    }
    settingsHolder[event.target.name] = val;
    this.setState(settingsHolder, ()=>{

      if(key === 'status' || key === 'useBounds'){
     
        this.passStateToQueryAndUpdateMap();
      }
    });
  }

  handleSubmit(event) {
    this.passStateToQueryAndUpdateMap();
    event.preventDefault();
  }

  clearSearch () { 
    let clearSearch = {
      status: 0,
      name: '',
      address: '',
    };
    this.setState(clearSearch);
    this.passStateToQueryAndUpdateMap();
  }

  componentDidMount() {

    this.map = L.map('map', {
      center: this.state.mapCenter,
      zoom: 16,
      minZoom:4,
      maxZoom:20,
      zoomSnap: 0.5,
      layers: [this.Esri_WorldImagery]
    });

		var baseLayers = {
      "Esri World": this.Esri_WorldImagery,
			"OSM Mapnik": this.osmMap
      // "Landscape": this.landMap,
    };
    L.control.layers(baseLayers).addTo(this.map);
    this.markers = L.markerClusterGroup({
      spiderfyOnMaxZoom : false,
      disableClusteringAtZoom : 15
    });
    this.map.addLayer(this.markers);
    this.geoJsonLayer = L.geoJSON();
    this.markers.addLayer(this.geoJsonLayer);
    this.map.on('moveend', (e)=>this.mapMoveEnd(e));
    this.updateMap();
  }
  
  componentWillUnmount() {
    this.map.remove();
  }

  render() {
    return (
      <div>
          <label>General Information: 
            <select value={this.state.status} name="status" onChange={this.handleChange} >
              <option value='0'>All</option>
              <option value='1'>Live</option>
              <option value='2'>Not Live</option>
              <option value='3'>Other</option>
            </select>
            <input type="checkbox" name="useBounds" title="Bound results to map view"
              checked={this.state.useBounds} onChange={this.handleChange}/>
          </label>
          <label>Site Name:
            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} onKeyDown={this.enterKeySubmit}/>
          </label>
          <label>Site Address:
            <input type="text" name="address" value={this.state.address} onChange={this.handleChange} onKeyDown={this.enterKeySubmit}/>
          </label>
          <input type="submit" value="Submit" onClick={this.handleSubmit}/>
          <input type="button" value="Clear" onClick={this.clearSearch}/>      
        <div className="map" id="map" style={style} />
        <div>Total Sites that match: {this.state.totalCount}</div>
      </div>
    );
  }
  //#region helper region

  updateMap(){
    // const unknownMarker = L.ExtraMarkers.icon({
    //   icon: 'fa-coffee',
    //   markerColor: 'blue',
    //   shape: 'square',
    //   prefix: 'fa'
    // });
    // const orangeMarker = L.ExtraMarkers.icon({
    //   icon: 'fa-coffee',
    //   markerColor: 'orange',
    //   shape: 'square',
    //   prefix: 'fa'
    // });
    // const blackMarker = L.ExtraMarkers.icon({
    //   icon: 'fa-coffee',
    //   markerColor: 'black',
    //   shape: 'circle',
    //   prefix: 'fa'
    // });
    // const yellowMarker = L.ExtraMarkers.icon({
    //   icon: 'fa-coffee',
    //   markerColor: 'yellow',
    //   shape: 'circle',
    //   prefix: 'fa'
    // });
    const bounds = this.map.getBounds();
    const params = this.query;
    if(this.state.useBounds){    
      const qbounds = this.mapapApi.createBoundsParam(bounds);
      Object.assign(params, qbounds);
    }

    this.mapapApi.sendSiteRequest({}, params)
    .then((res) => {
     return res.map(loc =>{
       const geojson = {};
       geojson['properties'] = { name:loc.name, address:loc.address, webStatus:loc.status, fileStatus:loc.filestatus };
       geojson['geometry'] = loc.location;
       geojson['type'] = 'Feature';
       return geojson;
      });
    })
    .then((locations) => {
      //Brute force replace 
      this.setState({totalCount: locations.length} );
      // console.log(locations.length, 'Found apts');
      this.markers.removeLayer(this.geoJsonLayer);
      this.geoJsonLayer = L.geoJson(locations, {

        pointToLayer: function (feature, latlng) {
          const status = feature.properties.webStatus;
          if(status === 1){
            return L.marker(latlng, {icon: placeNoMap});
          }else if (status == 10){
            return L.marker(latlng, {icon: placeComplete});
          }else if (status == 2){
            return L.marker(latlng, {icon: placeWithMap});
          }
          return L.marker(latlng, {icon: placeUnknownStatus});
        },
  
        onEachFeature: function (feature, layer) {
          var popupText = `<div>${feature.properties.name}<br>
                          ${feature.properties.address}<br>
                          ${feature.properties.fileStatus}:${feature.properties.webStatus}
                          </div>`;
          layer.bindTooltip(feature.properties.name, {direction:'bottom'});        
  
          layer.bindPopup(popupText);
        }
        
      });
      if(locations.length === 1){
        let loc = locations[0].geometry.coordinates;
        this.map.panTo(L.latLng(loc[1], loc[0]));
      }
      // this.geoJsonLayer.addLayer(L.geoJSON(gb));
      this.markers.addLayer(this.geoJsonLayer);
    });

  }
  mapMoveEnd(leafEvent){
    let center = leafEvent.target.getCenter();
    this.setState({mapCenter:[+center.lat, +center.lng]});
    if(this.state.useBounds){    
      this.updateMap();
    }
   
  }
  //#endregion
}
const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(MapapMap);
